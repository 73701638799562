// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      "addFirstList": "Tap the \"+\" \n to add a list",
      "addFirstTask": "Tap the \"+\" \n to add a task",
      "addList": "Add List",
      "addTask": "Add Task",
      "after": "After",
      "alert.offline.photo.header": "Currently Offline",
      "alert.offline.photo.message": "Please go back online to download images.",
      "archive": "Archive",
      "archived": "Archived",
      "assign": "Assign",
      "assignList": "Assign List",
      "assignMenu.deleteConfirmation.header": "Overwrite Assignments",
      "assignMenu.deleteConfirmation.message": "Any of this list’s existing assignments will be removed, and then assigned to the person you selected. \n \n Note: Completed tasks will not be reassigned.",
      "assignMenu.deselectUsers.assign": "No one (Unassign)",
      "assignMenu.deselectUsers.share": "No one (Unshare)",
      "assignMenu.header.list.assign": "Assign list to:",
      "assignMenu.header.list.share": "Share list with:",
      "assignMenu.header.list.shareOwner": "Share / Add Owners:",
      "assignMenu.header.task.assign_one": "Assign task to:",
      "assignMenu.header.task.assign_other": "Assign tasks to:",
      "assignTask": "Assign Task",
      "assignTask.to": "Assign task to:",
      "banner.missedRequiredUpdate.main": "An Update is Required",
      "banner.writeAccessRestricted.info.body.nonPayment.1": "Due to past due/payment issues, your company's access has been limited.",
      "banner.writeAccessRestricted.info.body.nonPayment.2": "Please contact your admin to update payment information so your access may continue.",
      "banner.writeAccessRestricted.info.header": "Limited",
      "banner.writeAccessRestricted.main": "Access is Limited",
      "back": "Back",
      "before": "Before",
      "button.acknowledge": "OK",
      "button.later": "Later",
      "button.update": "Update",
      "button.illAddTasks": "I'll Add Tasks",
      "button.addTasksForMe": "Add Tasks For Me",
      "calendarView.clear": "Clear",
      "calendarView.repeat": "Repeat",
      "calendarView.time": "Time",
      "cancel": "Cancel",
      "change": "Change",
      "complete": "Complete",
      "completeList": "Complete List",
      "completeTask": "Complete Task",
      "completeTasks": "Complete Tasks",
      "completed": "Completed",
      "completionReport": "Completion Report",
      "confirmation.agreement.button.accept": "Accept",
      "confirmation.agreement.button.reject": "Do Not Accept",
      "confirmation.agreement.header.default": "Welcome!",
      "confirmation.agreement.header.updated": "Updated",
      "confirmation.agreement.body.default": "To use Otuvy Frontline, you'll need to accept our <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Privacy Policy</a> and <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Terms of Service</a>.",
      "confirmation.agreement.body.updated": "Our <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Terms of Service</a> and <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Privacy Policy</a> have been updated. To continue using Otuvy Frontline, you'll need to accept these updates.",
      "confirmation.delete.account.link": "Yes, delete my account",
      "confirmation.delete.image.confirm": "Delete image",
      "confirmation.delete.image.header": "Delete Image",
      "confirmation.delete.image.message": "You will not be able to recover this image once deleted.",
      "confirmation.delete.list.confirm": "Delete the list",
      "confirmation.delete.list.header": "Delete this list?",
      "confirmation.delete.list.message": "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.profileImage.header": "Are you sure you want to remove your profile image?",
      "confirmation.delete.task.confirm": "Delete task",
      "confirmation.delete.task.generic.confirm": "Delete",
      "confirmation.delete.task.generic.header": "Confirm Deletion",
      "confirmation.delete.task.generic.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.delete.task.header": "Delete Task",
      "confirmation.delete.task.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.dueDate.apply": "Yes, apply the due date",
      "confirmation.dueDate.existingWillBeReplaced": "Any existing due date will be replaced",
      "confirmation.dueDate.replace": "Replace Due Dates?",
      "contactSupport": "Contact Support",
      "date": "Date",
      "dayOfTheWeek": "Day of the Week",
      "default.list.name": "Untitled List",
      "default.task.name": "Untitled Task",
      "deficiencies": "Deficiencies",
      "delete": "Delete",
      "deleteTasks": "Delete tasks",
      "deselect": "Deselect",
      "deselectAll": "Deselect All",
      "done": "Done",
      "dueDate": "Due Date",
      "dueDateAndRepeat": "Due Date and Repeat...",
      "duplicate": "Duplicate",
      "duplicateList": "Duplicate List",
      "duplicateTask": "Duplicate task",
      "duplicateTasks": "Duplicate tasks",
      "edit": "Edit",
      "email": "Email",
      "employeeScorecard": "Employee Scorecard",
      "error.name.required": "Name is Required",
      "error.phone.invalid": "Phone appears to be invalid",
      "error.phone.required": "Phone is Required",
      "error.signin.incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "errorAlert.default.header": "Whoops!",
      "errorAlert.default.message": "We ran into an error on our side. Sorry about that! If it happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generateList.contentFilter.message": "One or more of your answers was flagged as containing inappropriate content. Please modify your answers and try submitting again.<br/><br/>If this error happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generateList.generic.message": "We encountered an issue while creating the list. Sorry about that! You can try submitting again.<br/><br/>If it happens multiple times please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.list.unavailable": "It looks like this isn’t available to you. If you have questions, please check with your administrator, or <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorPage": "Hi, I'm the Error Page.",
      "errorPage.backIntoIt": "Let's get back into it.",
      "errorPage.header": "Hi, I'm the Error Page.",
      "errorPage.message": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "errorPage.unavailableList.message": "'It looks like this list is no longer available'.",
      "errorPage.sorry": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "favorites": "Favorites",
      "feedback.list.completed": "Great work! You've completed the list.",
      "feedback.task.completed": "Great work! Task completed \"{{taskName}}\"",
      "generatedTask.instructions.duration": "({{duration}} min)",
      "incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "instructions": "Instructions",
      "instructions.link.add": "+ Add a Link",
      "instructions.link.delete": "Delete Link",
      "instructions.link.delete.confirmation.text": "Are you sure you want to delete this link?",
      "instructions.link.textToDisplay.label": "Text to Display (optional)",
      "instructions.link.textToDisplay.placeholder": "e.g. 10 Steps to Lawn Edging",
      "instructions.link.save": "Save Link",
      "label.selectAllCompletedTasks": "Select all completed tasks",
      "label.selectAllTasks": "Select all tasks",
      "label.task.instructions": "Instructions",
      "label.task.name": "Task",
      "label.task.notes": "Notes",
      "label.task.taskName": "Task",
      "link.deleteMyAccount": "Delete my account",
      "link.faq": "Frequently Asked Questions",
      "link.feedback": "Give Feedback",
      "link.privacyPolicy": "Privacy Policy",
      "link.support": "Contact Support",
      "link.termsAndConditions": "Terms and Conditions",
      "link.termsOfService": "Terms of Service",
      "link.voteOnFeatures": "Vote On Features",
      "list": "List",
      "list_one": "List",
      "list_other": "Lists",
      "list.archive": "Archive List",
      "list.unarchive": "Unarchive List",
      "list.owner": "List Owner",
      "list.rename": "Rename List",
      "listDetails.header": "List Details",
      "listName": "Name of List",
      "lists": "Lists",
      "loading.generateList": "Creating tasks...",
      "loading.generic": "Please wait...",
      "logout": "Log out",
      "menu.action.hideArchivedLists": "Hide Archived Lists", 
      "menu.action.showArchivedLists": "Show Archived Lists",
      "menu.action.hideCompletedLists": "Hide Completed Lists",
      "menu.action.hideCompletedTasks": "Hide Completed Tasks",
      "menu.action.scanQRCodes": "Scan QR Codes",
      "menu.action.select.lists": "Select Lists",
      "menu.action.select.tasks": "Select Tasks",
      "menu.action.share.list": "Share List",
      "menu.action.shareListOrAddOwners": "Share Lists or Add Owners",
      "menu.action.showCompletedLists": "Show Completed Lists",
      "menu.action.showCompletedTasks": "Show Completed Tasks",
      "menu.action.sort": "Sort by...",
      "menu.duedateAndRepeat": "Due date and repeat",
      "menu.header.sort": "Sort by...",
      "menu.helpAndAbout": "Help & About",
      "modal.generateList.header": "Questions",
      "modal.generateList.question1.accessibilityLabel": "List Name*",
      "modal.generateList.question1.label": "Let's get started with your list",
      "modal.generateList.question1.placeholder": "List Name, etc.",
      "modal.generateList.question2.label": "What type of tasks?",
      "modal.generateList.question2.placeholder": "e.g. Cleaning, Repair, Installation, etc.",
      "modal.generateList.question3.label": "What is the main objective of the list?",
      "modal.generateList.question3.placeholder": "Give detailed tasks for sanitizing the kitchen.",
      "modal.generateList.button.accept": "Add Tasks for Me",
      "multiSelectMenu.deselect.archived": "Deselect All Archived",
      "multiSelectMenu.deselect.completed": "Deselect All Completed",
      "multiSelectMenu.deselect.incomplete": "Deselect All Incomplete",
      "multiSelectMenu.dueDateAndRepeat": "Due Date / Repeat",
      "multiSelectMenu.photoRequired": "Require Photo",
      "multiSelectMenu.photoRequired.makeOptional": "Disable Photo",
      "multiSelectMenu.select.archived": "Select All Archived",
      "multiSelectMenu.select.completed": "Select All Completed",
      "multiSelectMenu.select.incomplete": "Select All Incomplete",
      "name": "Name",
      "newCodeRequest": "Send me a new code",
      "newVerificationCodeSent.header": "Still waiting for a code?",
      "newVerificationCodeSent.message": "Use this time to meditate or check us out at <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> to learn more about us. You may also reach out to your account administrator to troubleshoot the issue.",
      "none": "None",
      "notes": "Notes",
      "notifications.notification.inApp": "In-App Notifications",
      "notifications.notification.gotoOSSettings.label": "Phone Settings",
      "notifications.listDone": "List Done",
      "notifications.tasksGetAssignedToMe": "Tasks Get Assigned to Me",
      "notifications.listsGetAssignedToMe": "Become List Owner",
      "notifications.pastDueTasks": "Past Due Tasks",
      "notifications.pastDueTasks.assignedToMe": "Assigned to me",
      "notifications.pastDueTasks.oneOffs": "One-offs",
      "notifications.pastDueTasks.repeating": "repeating",
      "notifications.pastDueTasks.onSharedList": "On a Shared List",
      "popover.generateList.offline": "You must be online to add tasks automatically.",
      "offline": "Offline",
      "offlineIndicator.popover.text": "Your phone is offline. But don’t worry! You can keep working and we’ll update everything when you’re back online.",
      "offlineNoticeHeader": "No internet \t(",
      "offlineNoticeMessage": "We should reconnect soon. To find out if it's not just our app, you can... <br/> <br/> <ul><li> Check your phone settings</li><li>Check other Apps</li><li>Check WiFi signal strength</li></ul><br/>And don't worry we save your work as we go!",
      "option.sort.activity": "Recent Activity",
      "option.sort.assignedTo": "Assigned To",
      "option.sort.created": "Creation Date",
      "option.sort.due": "Due Date",
      "option.sort.name": "Name",
      "otpCode": "Code",
      "otpPrompt": "We'll send a code to your email so you can start using Otuvy Frontline",
      "otpSent": "We've sent a verification code to your email. Please enter it below.",
      "owner": "Owner",
      "preview": "Preview",
      "phone": "Phone",
      "picturesAbbreviated": "Pics",
      "placeholder.email": "jane.doe@example.com",
      "placeholder.generic": "Tap to enter text",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Your verification code",
      "product.version": "v.",
      "registrationGreeting": "Welcome! Let's finish setting up your account...",
      "remove": "Remove",
      "repeat": "Repeat",
      "repeatMenu.custom": "Custom",
      "repeatMenu.custom.day": "Day",
      "repeatMenu.custom.days": "Days",
      "repeatMenu.custom.fifth": "Fifth",
      "repeatMenu.custom.first": "First",
      "repeatMenu.custom.fourth": "Fourth",
      "repeatMenu.custom.friday": "Friday",
      "repeatMenu.custom.monday": "Monday",
      "repeatMenu.custom.month": "Month",
      "repeatMenu.custom.months": "Months",
      "repeatMenu.custom.on": "On",
      "repeatMenu.custom.saturday": "Saturday",
      "repeatMenu.custom.second": "Second",
      "repeatMenu.custom.sunday": "Sunday",
      "repeatMenu.custom.third": "Third",
      "repeatMenu.custom.thursday": "Thursday",
      "repeatMenu.custom.title": "Repeats Every:",
      "repeatMenu.custom.tuesday": "Tuesday",
      "repeatMenu.custom.wednesday": "Wednesday",
      "repeatMenu.custom.week": "Week",
      "repeatMenu.custom.weeks": "Weeks",
      "repeatMenu.custom.year": "Year",
      "repeatMenu.custom.years": "Years",
      "repeatMenu.daily": "Daily",
      "repeatMenu.monthly": "Monthly",
      "repeatMenu.monthlyByWeekday": "Monthly on the",
      "repeatMenu.none": "None",
      "repeatMenu.weekdays": "Every Weekday(Mon-Fri)",
      "repeatMenu.weekly": "Weekly",
      "repeatMenu.yearly": "Yearly",
      "reportBuilder.modal.deleteConfirmation.header": "Hold up!",
      "reportBuilder.modal.deleteConfirmation.message": "If you leave now, your report will not be sent, and you will need to make a new one. Would you like to continue?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Yes, Delete This Report",
      "reportBuilder.modal.header.completionReport": "Completion Report",
      "reportBuilder.modal.header.employeeScorecard": "Employee Scorecard",
      "reportBuilder.whoGetsItPage.header": "Who gets it?",
      "reportBuilder.whoGetsItPage.message": "Enter one or more emails.",
      "reportBuilder.whoGetsItPage.input.label": "Send To:",
      "reportBuilder.dateRangePage.header": "Date Range",
      "reportBuilder.dateRangePage.message": "Include data from this date range...",
      "reportBuilder.dateRangePage.range.last24hours": "Last 24 Hours",
      "reportBuilder.dateRangePage.range.last7days": "Last 7 Days",
      "reportBuilder.dateRangePage.range.last30days": "Last 30 Days",
      "reportBuilder.dateRangePage.range.last90days": "Last 90 Days",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Last Full Month",
      "reportBuilder.dateRangePage.range.custom": "Custom Date Range... (see below)", 
      "reportBuilder.dateRangePage.range.custom.firstDate": "First Date:",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Last Date:",
      "reportBuilder.employeeSelectionPage.header" : "Whose Data",
      "reportBuilder.employeeSelectionPage.message": "Whose tasks should we include?",
      "reportBuilder.previewPage.details.listsIncluded": "Lists Included:",
      "reportBuilder.previewPage.details.recipients": "Recipients:",
      "reportBuilder.previewPage.emailNow": "Email Now",
      "reportBuilder.previewPage.radioGroup.header": "Show",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Add Comments",
      "reportBuilder.previewPage.radioGroup.option.lists": "Lists (recommended)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Lists and tasks",
      "reportBuilder.previewPage.radioGroup.option.notes": "Task notes",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Report Name",
      "reportBuilder.whichListPage.clearFilters": "Clear Filters",
      "reportBuilder.whichListsPage.header": "Which Lists?",
      "reportBuilder.whoseDataPage.header": "Whose Data?",
      "reportBuilder.filterSearchbar.byListName": "By List Name",
      "reportBuilder.filterSearchbar.byPerson": "By Person",
      "reportBuilder.toast.deleted": "Report Deleted!",
      "reportBuilder.toast.submitted": "Report Sent!",
      "reports": "Reports",
      "reports.comletionReport": "Completion Report",
      "reports.newCompletionReport": "New Completion Report",
      "reports.employeeScorecard": "Employee Scorecard",
      "reports.newEmployeeScorecard": "New Employee Scorecard",
      "required": "Required",
      "requirePhoto": "Require a Photo",
      "requirePhoto.makeOptional": "Make Optional",
      "requirePhoto.takePhoto": "Take Photo (required)",
      "rrule.on.weekly": "on",
      "rrule.on.yearly": "on",
      "rrule.weekdays": "(Mon-Fri)",
      "searchForLists": "Search for lists...",
      "searchForTasks": "Search for tasks...",
      "searchForTimeZone": "Search for time zone...",
      "searchInput.placeholder": "Search...",
      "select": "Select",
      "selected": "Selected",
      "selectAll": "Select All",
      "sendCode": "Send Verification Code",
      "settings": "Settings",
      "settings.darkMode": "Dark Mode",
      "settings.language": "Language",
      "settings.wifiOnly": "Use Wifi Only",
      "settings.wifiOnly.hint": "If you want the app to use your cell phone data, leave this option disabled. If you want to preserve your cell data, enable it.",
      "settings.notifications": "Notifications",
      "settings.notifications.enabled": "Enabled",
      "settings.notifications.enableDeviceLocationFirst": "Enable Device Location First",
      "settings.notifications.disabled": "Disabled",
      "settings.notifications.loading": "Loading...",
      "settings.notifications.unavailable": "N/A (Mobile Only)",
      "settings.locations": "Location",
      "settings.locations.appAccessToLocation": "App Access to Location",
      "settings.locations.enabled": "Enabled",
      "settings.locations.deviceLocation": "Device Location",
      "settings.locations.disabled": "Disabled",
      "settings.locations.loading": "Loading...",
      "settings.locations.goToAppSettings": "Go to App Settings",
      "settings.locations.goToPhoneSettings": "Go to Phone Settings",
//      "settings.locations.unavailable": "N/A (Mobile Only)",
      "share": "Share",
      "shared": "Shared",
      "shareList": "Share List",
      "submit": "Submit",
      "syncIndicator.popover.text": "Your data is syncing. But don’t worry! You can keep working and we’ll update everything in the meantime.",
      "task": "Task",
      "task_one": "Task",
      "task_other": "Tasks",
      "tasks": "Tasks",
      "timeZone": "Time Zone",
      "today": "Today",
      "tomorrow": "Tomorrow",
      "toolbar.tab.home": "Home",
      "toolbar.tab.reports": "Reports",
      "toolbar.tab.settings": "Settings",
      "unarchive": "Unarchive",
      "undefinedList.name": "Undefined List",
      "update.title": "Level up!",
      "update.body": "An app update is available for you! We're always working to bring you the best stuff.",
      "Yesterday": "Yesterday",
    }
  },
  es: {
    translation: {
      "addFirstList": "Toca el simbolo de \"+\" \n para agregar una lista",
      "addFirstTask": "Toca el simbolo de \"+\" \n para agregar una tarea",
      "addList": "Agregar Lista",
      "addTask": "Agregar Tarea",
      "after": "Después",
      "archive": "Archivar",
      "archived": "Archivadas",
      "assign": "Asignar",
      "assignList": "Asignar lista",
      "assignMenu.deleteConfirmation.header": "Sobrescribir asignaciones",
      "assignMenu.deleteConfirmation.message": "Cualquier asignación existente de esta lista se eliminará y luego se asignará a la persona que seleccionó. \n \n Nota: Las tareas completadas no se reasignarán.",
      "assignMenu.deselectUsers.assign": "Nadie (Desasignar)",
      "assignMenu.deselectUsers.share": "Nadie (No compartir)",
      "assignMenu.header.list.assign": "Asignar lista a:",
      "assignMenu.header.list.share": "Compartir lista con:",
      "assignMenu.header.list.shareOwner": "Compartir / Agregar Propietarios:",
      "assignMenu.header.task.assign_one": "Asignar tarea a:",
      "assignMenu.header.task.assign_other": "Asignar tareas a:",
      "assignTask": "Asignar Tarea",
      "assignTask.to": "Asignar tarea a:",
      "back": "Atrás",
      "before": "Antes",
      "button.acknowledge": "Aceptar",
      "button.later": "Más tarde",
      "button.update": "Actualizar",
      "button.illAddTasks" : "Agregar Tareas",
      "button.addTasksForMe": "Agregar Tareas por Mí",
      "calendarView.clear": "Limpiar",
      "calendarView.repeat": "Repetir",
      "calendarView.time": "Hora",
      "cancel": "Cancelar",
      "change": "Cambiar",
      "complete": "Completar",
      "completeList": "Completar Lista",
      "completeTask": "Completar Tarea",
      "completeTasks": "Completar Tareas",
      "completed": "Completadas",
      "completionReport": "Reporte de Finalización",
      "confirmation.agreement.button.accept": "Aceptar",
      "confirmation.agreement.button.reject": "No Aceptar",
      "confirmation.agreement.header.default": "¡Bienvenido!",
      "confirmation.agreement.header.updated": "Actualizado",
      "confirmation.agreement.body.default": "Para usar Otuvy Frontline, deberá aceptar nuestra <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidad</a> y <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Términos de Servicio</a>.",
      "confirmation.agreement.body.updated": "Nuestros <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Términos de Servicio</a> y <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidad</a> han sido actualizados. Para continuar usando Otuvy Frontline, deberá aceptar estas actualizaciones.",
      "confirmation.delete.account.link": "Sí, eliminar mi cuenta.",
      "confirmation.delete.image.confirm": "Eliminar imagen",
      "confirmation.delete.image.header": "Eliminar Imagen",
      "confirmation.delete.image.message": "No podrá recuperar esta imagen una vez eliminada.",
      "confirmation.delete.list.confirm": "Eliminar la lista",
      "confirmation.delete.list.header": "¿Eliminar esta lista?",
      "confirmation.delete.list.message": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.profileImage.header": "¿Está seguro de que desea eliminar su imagen de perfil?",
      "confirmation.delete.task.confirm": "Eliminar tarea",
      "confirmation.delete.task.generic.confirm": "Eliminar",
      "confirmation.delete.task.generic.header": "Confirmar Eliminación",
      "confirmation.delete.task.generic.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.delete.task.header": "Eliminar Tarea",
      "confirmation.delete.task.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.dueDate.apply": "Sí, aplicar la fecha límite",
      "confirmation.dueDate.existingWillBeReplaced": "Cualquier fecha límite existente será reemplazada",
      "confirmation.dueDate.replace": "¿Reemplazar las fechas límite?",
      "contactSupport": "Contactar a Soporte",
      "date": "Fecha",
      "dayOfTheWeek": "Día de la Semana",
      "default.list.name": "Lista sin título",
      "default.task.name": "Tarea sin título",
      "deficiencies": "Deficiencias",
      "delete": "Eliminar",
      "deleteTasks": "Eliminar tareas",
      "deselectAll": "Deseleccionar Todo",
      "done": "Hecho",
      "dueDate": "Fecha Límite",
      "dueDateAndRepeat": "Fecha límite y repetición...",
      "duplicate": "Duplicar",
      "duplicateList": "Duplicar Lista",
      "duplicateTask": "Duplicar tarea",
      "duplicateTasks": "Duplicar tareas",
      "edit": "Editar",
      "email": "Correo electrónico",
      "employeeScorecard": "Evaluación del Empleado",
      "error.name.required": "El nombre es obligatorio",
      "error.phone.invalid": "El teléfono parece no ser válido",
      "error.phone.required": "El teléfono es obligatorio",
      "error.signin.incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "errorAlert.default.header": "Ups!",
      "errorAlert.default.message": "Nos encontramos con un error de nuestro lado. ¡Lo lamentamos! Si sucede varias veces, no dude en <a href='mailto:support@otuvy.com'>Ponerse en Contacto con el Soporte</a>.",
      "errorAlert.generateList.contentFilter.message": "Una o más de sus respuestas fue marcada como contenido inapropiado. Modifique sus respuestas e intente enviar nuevamente.<br/><br/>Si este error sucede varias veces, no dude en <a href='mailto=:support@otuvy.com'>Contactar Soporte</a>.",
      "errorAlert.generateList.generic.message": "Nos encontramos con un problema al crear la lista. ¡Lo lamentamos! Puede intentar enviar nuevamente.<br/><br/>Si sucede varias veces, no dude en <a href='mailto=:support@otuvy.com'>Contactar Soporte</a>.",
      "errorAlert.list.unavailable": "Parece que esto no está disponible para usted. Si tiene preguntas, consulte con su administrador o <a href='mailto=:support@otuvy.com'>Comuníquese con Soporte</a>.",
      "errorPage": "Hola, soy la Página de Error.",
      "errorPage.backIntoIt": "Retomemos.",
      "errorPage.header": "Hola, soy la Página de Error.",
      "errorPage.message": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "errorPage.sorry": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "favorites": "Favoritos",
      "feedback.list.completed": "¡Buen trabajo! Ha completado la lista.",
      "feedback.task.completed": "¡Buen trabajo! Tarea completada \"{{taskName}}\"",
      "incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "instructions": "Instrucciones",
      "instructions.link.add": "+ Agregar un Enlace",
      "instructions.link.delete": "Eliminar Enlace",
      "instructions.link.delete.confirmation.text": "¿Está seguro de que desea eliminar este enlace?",
      "instructions.link.textToDisplay.label": "Texto a Mostrar (opcional)",
      "instructions.link.textToDisplay.placeholder": "10 Pasos para cortar del Césped",
      "instructions.link.save": "Guardar",
      "label.selectAllCompletedTasks": "Seleccionar todas las tareas completadas",
      "label.selectAllTasks": "Seleccionar todas las tareas",
      "label.task.instructions": "Instrucciones",
      "label.task.name": "Tarea",
      "label.task.notes": "Notas",
      "label.task.taskName": "Tarea",
      "link.deleteMyAccount": "Borrar mi cuenta",
      "link.faq": "Preguntas frecuentes",
      "link.feedback": "Dar sugerencias",
      "link.privacyPolicy": "Política de privacidad",
      "link.support": "Contactar a Soporte",
      "link.termsAndConditions": "Términos y condiciones",
      "link.termsOfService": "Términos de servicio",
      "link.voteOnFeatures": "Votar por Funcionalidades",
      "list": "Lista",
      "list_one": "Lista",
      "list_other": "Listas",
      "list.archive": "Archivar Lista",
      "list.unarchive": "Desarchivar Lista",
      "list.owner": "Propietario de la lista",
      "list.rename": "Cambiar Nombre",
      "listDetails.header": "Detalles de la Lista",
      "listName": "Nombre de la Lista",
      "lists": "Listas",
      "loading.generateList": "Creando tareas...",
      "loading.generic": "Espere...",
      "logout": "Cerrar sesión",
      "menu.action.hideArchivedLists": "Ocultar listas archivadas",
      "menu.action.showArchivedLists": "Mostrar listas archivadas",
      "menu.action.hideCompletedLists": "Ocultar listas completadas",
      "menu.action.hideCompletedTasks": "Ocultar tareas completadas",
      "menu.action.scanQRCodes": "Escanear códigos QR",
      "menu.action.select.lists": "Seleccionar listas",
      "menu.action.select.tasks": "Seleccionar tareas",
      "menu.action.share.list": "Compartir lista",
      "menu.action.shareListOrAddOwners": "Compartir listas o agregar propietarios",
      "menu.action.sort": "Ordenar por...",
      "menu.action.showCompletedLists": "Mostrar listas completadas",
      "menu.action.showCompletedTasks": "Mostrar tareas completadas",
      "menu.duedateAndRepeat": "Fecha límite y repetición",
      "menu.header.sort": "Ordenar por...",
      "menu.helpAndAbout": "Ayuda y Acerca de",
      "modal.generateList.header": "Preguntas",
      "modal.generateList.question1.accessibilityLabel": "Nombre de la Lista*",
      "modal.generateList.question1.label": "Comencemos con tu lista",
      "modal.generateList.question1.placeholder": "Nombre de la Lista, etc.",
      "modal.generateList.question2.label": "¿Qué tipo de tareas?",
      "modal.generateList.question2.placeholder": "Ej. Limpieza, Reparación, Instalación, etc.",
      "modal.generateList.question3.label": "¿Cuál es el objetivo principal de la lista?",
      "modal.generateList.question3.placeholder": "Dar tareas detalladas para desinfectar...",
      "modal.generateList.button.accept": "Agregar Tareas por Mí",
      "multiSelectMenu.deselect.archived": "Deseleccionar Todo Archivado",
      "multiSelectMenu.deselect.completed": "Deseleccionar Todo Completado",
      "multiSelectMenu.deselect.incomplete": "Deseleccionar Todo Incompleto",
      "multiSelectMenu.dueDateAndRepeat": "Fecha límite / Repetir",
      "multiSelectMenu.photoRequired": "Requerir Foto",
      "multiSelectMenu.photoRequired.makeOptional": "Desactivar Foto",
      "multiSelectMenu.select.archived": "Seleccionar Todo Archivado",
      "multiSelectMenu.select.completed": "Seleccionar Todo Completado",
      "multiSelectMenu.select.incomplete": "Seleccionar Todo Incompleto",
      "name": "Nombre",
      "newCodeRequest": "Enviarme un nuevo código",
      "newVerificationCodeSent.header": "Aún esperando un código?",
      "newVerificationCodeSent.message": "Use este tiempo para meditar o visitarnos en <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> para obtener más información sobre nosotros. También puede comunicarse con su administrador de cuentas para solucionar el problema.",
      "none": "Ninguno",
      "notes": "Notas",
      "notifications.notification.inApp": "Notificaciones",
      "notifications.listDone": "Listas Completadas",
      "notifications.tasksGetAssignedToMe": "Tareas Asignadas",
      "notifications.listsGetAssignedToMe": "Listas Asignadas",
      "notifications.pastDueTasks": "Tareas vencidas",
      "notifications.pastDueTasks.assignedToMe": "Asignado a mí",
      "notifications.pastDueTasks.oneOffs": "Uno-fuera",
      "notifications.pastDueTasks.repeating": "Repitiendo",
      "notifications.pastDueTasks.iveAssigned": "He asignado",
      "notifications.pastDueTasks.onSharedList": "En una lista compartida",
      "offline": "Sin conexión",
      "offlineIndicator.popover.text": "Tu teléfono está sin conexión. ¡Pero no te preocupes! Puede seguir trabajando y actualizaremos todo cuando vuelva a estar en línea.",
      "offlineNoticeHeader": "Sin internet \t(",
      "offlineNoticeMessage": "Deberíamos reconectarnos pronto. Para saber si no es solo nuestra aplicación, puede... <br/><br/><ul><li>Verificar la configuración de su teléfono</li><li>Verificar otras aplicaciones</li><li>Verificar la intensidad de la señal wifi</li></ul><br/>Y no se preocupe, ¡guardamos su trabajo a medida que avanzamos!",
      "option.sort.activity": "Actividad Reciente",
      "option.sort.assignedTo": "Asignado a",
      "option.sort.created": "Fecha de Creación",
      "option.sort.due": "Fecha límite",
      "option.sort.name": "Nombre",
      "otpCode": "Código",
      "otpPrompt": "Le enviaremos un código a su correo electrónico para que pueda comenzar a usar Otuvy Frontline",
      "otpSent": "Hemos enviado un código de verificación a su correo electrónico. Ingréselo a continuación.",
      "owner": "Propietario",
      "preview": "Vista previa",
      "phone": "Teléfono",
      "picturesAbbreviated": "Fotos",
      "placeholder.email": "maria.sanchez@ejemplo.com",
      "placeholder.generic": "Tocar para ingresar texto",
      "placeholder.name": "María Sánchez",
      "placeholder.otp": "Su código de verificación",
      "product.version": "v.",
      "registrationGreeting": "¡Bienvenido! Terminemos de configurar su cuenta...",
      "remove": "Eliminar",
      "repeat": "Repetir",
      "repeatMenu.custom": "Personalizado",
      "repeatMenu.custom.day": "Día",
      "repeatMenu.custom.days": "Días",
      "repeatMenu.custom.fifth": "Quinto",
      "repeatMenu.custom.first": "Primer",
      "repeatMenu.custom.fourth": "Cuarto",
      "repeatMenu.custom.friday": "Viernes",
      "repeatMenu.custom.monday": "Lunes",
      "repeatMenu.custom.month": "Mes",
      "repeatMenu.custom.months": "Meses",
      "repeatMenu.custom.on": "El",
      "repeatMenu.custom.saturday": "Sábado",
      "repeatMenu.custom.second": "Segundo",
      "repeatMenu.custom.sunday": "Domingo",
      "repeatMenu.custom.third": "Tercero",
      "repeatMenu.custom.thursday": "Jueves",
      "repeatMenu.custom.title": "Repetir cada:",
      "repeatMenu.custom.tuesday": "Martes",
      "repeatMenu.custom.wednesday": "Miércoles",
      "repeatMenu.custom.week": "Semana",
      "repeatMenu.custom.weeks": "Semanas",
      "repeatMenu.custom.year": "Año",
      "repeatMenu.custom.years": "Años",
      "repeatMenu.daily": "Diario",
      "repeatMenu.monthly": "Mensualmente",
      "repeatMenu.monthlyByWeekday": "Mensual el",
      "repeatMenu.none": "Ninguno",
      "repeatMenu.weekdays": "Cada dia Laborable(Lun-Vie)",
      "repeatMenu.weekly": "Semanalmente",
      "repeatMenu.yearly": "Anualmente",
      "reportBuilder.modal.deleteConfirmation.header": "¡Espera!",
      "reportBuilder.modal.deleteConfirmation.message": "Si te vas ahora, tu reporte no se enviará y tendrás que hacer uno nuevo. ¿Te gustaría continuar?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Sí, Eliminar Este Reporte",
      "reportBuilder.modal.header.completionReport": "Reporte de Finalización",
      "reportBuilder.modal.header.employeeScorecard": "Evaluación del Empleado",
      "reportBuilder.whoGetsItPage.header": "¿Quién lo recibe?",
      "reportBuilder.whoGetsItPage.message": "Ingrese uno o más correos electrónicos.",
      "reportBuilder.whoGetsItPage.input.label": "Enviar a:",
      "reportBuilder.dateRangePage.header": "Rango de Fechas",
      "reportBuilder.dateRangePage.message": "Incluir datos de este rango de fechas...",
      "reportBuilder.dateRangePage.range.last24hours": "Últimas 24 horas",
      "reportBuilder.dateRangePage.range.last7days": "Últimos 7 días",
      "reportBuilder.dateRangePage.range.last30days": "Últimos 30 días",
      "reportBuilder.dateRangePage.range.last90days": "Últimos 90 días",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Último mes completo",
      "reportBuilder.dateRangePage.range.custom": "Rango de fechas personalizado... (ver abajo)",
      "reportBuilder.dateRangePage.range.custom.firstDate": "Primera Fecha:",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Última Fecha:",
      "reportBuilder.employeeSelectionPage.header" : "De quién son los datos",
      "reportBuilder.employeeSelectionPage.message": "De quiénes deberíamos incluir las tareas?",
      "reportBuilder.previewPage.details.listsIncluded": "Listas Incluidas:",
      "reportBuilder.previewPage.details.recipients": "Destinatarios:",
      "reportBuilder.previewPage.emailNow": "Enviar Ahora",
      "reportBuilder.previewPage.radioGroup.header": "Mostrar",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Agregar Comentarios",
      "reportBuilder.previewPage.radioGroup.option.lists": "Listas (recomendado)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Listas y tareas",
      "reportBuilder.previewPage.radioGroup.option.notes": "Notas de tareas",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Nombre del Reporte",
      "reportBuilder.whichListPage.clearFilters": "Borrar Filtros",
      "reportBuilder.whichListsPage.header": "¿Qué listas?",
      "reportBuilder.filterSearchbar.byListName": "Por Nombre de Lista",
      "reportBuilder.filterSearchbar.byPerson": "Por Persona",
      "reportBuilder.toast.deleted": "¡Reporte Eliminado!",
      "reportBuilder.toast.submitted": "¡Reporte Enviado!",
      "reports": "Reportes",
      "reports.comletionReport": "Reporte de Finalización",
      "reports.newCompletionReport": "Nuevo Reporte de Finalización",
      "reports.employeeScorecard": "Evaluación del Empleado",
      "reports.newEmployeeScorecard": "Nueva Evaluación del Empleado",
      "required": "Requerido",
      "requirePhoto": "Requerir una Foto",
      "requirePhoto.makeOptional": "Hacer Opcional",
      "requirePhoto.takePhoto": "Tomar Foto (requerido)",
      "rrule.on.weekly": "los",
      "rrule.on.yearly": "el",
      "rrule.weekdays": "(Lun-Vie)",
      "searchForLists": "Buscar listas...",
      "searchForTasks": "Buscar tareas...",
      "searchForTimeZone": "Buscar zona horaria...",
      "searchInput.placeholder": "Buscar",
      "select": "Seleccionar",
      "selected_one": "Seleccionado",
      "selected_other": "Seleccionados",
      "selectAll": "Seleccionar Todo",
      "sendCode": "Enviar Código de Verificación",
      "settings": "Ajustes",
      "settings.darkMode": "Modo Oscuro",
      "settings.language": "Idioma",
      "settings.notifications": "Notificaciones",
      "settings.wifiOnly": "Usar Solo Wifi",
      "settings.wifiOnly.hint": "Si desea que la aplicación use los datos de su teléfono celular, deje esta opción desactivada. Si desea conservar los datos de su celular, actívela.",
      "settings.notifications.enabled": "Activado",
      "settings.notifications.enableDeviceLocationFirst": "Habilitar Ubicación del Dispositivo Primero",
      "settings.notifications.disabled": "Desactivado",
      "settings.notifications.loading": "Cargando...",
      "settings.notifications.unavailable": "N/A (Solo en Dispositivos Móviles)",
      "settings.locations": "Ubicación",
      "settings.locations.appAccessToLocation": "Acceso de la Aplicación a la Ubicación",
      "settings.locations.enabled": "Activado",
      "settings.locations.deviceLocation": "Ubicación del Dispositivo",
      "settings.locations.disabled": "Desactivado",
      "settings.locations.loading": "Cargando...",
      "settings.locations.goToAppSettings": "Configuración de la Aplicación",
      "settings.locations.goToPhoneSettings": "Configuración del Dispositivo",
      "share": "Compartir",
      "shared": "Compartida",
      "shareList": "Compartir Lista",
      "submit": "Enviar",
      "task": "Tarea",
      "task_one": "Tarea",
      "task_other": "Tareas",
      "tasks": "Tareas",
      "timeZone": "Hora Local",
      "today": "Hoy",
      "tomorrow": "Mañana",
      "toolbar.tab.home": "Inicio",
      "toolbar.tab.reports": "Reportes",
      "toolbar.tab.settings": "Ajustes",
      "unarchive": "Desarchivar",
      "undefinedList.name": "Undefined List",
      "update.title": "¡Actualiza!",
      "update.body": "¡Hay una actualización de la aplicación disponible para ti! Siempre estamos trabajando para brindarte lo mejor.",
      "Yesterday": "Ayer",
    }
  },
    fr: {
      translation: {
      "repeatMenu.monthlyByWeekday": "Mensuel le",  
      "rrule.on.weekly": "le",
      "rrule.on.yearly": "le",
      "rrule.weekdays": "(Lun-Ven)",
    }
  },
  pt: {
    translation: {
      "repeatMenu.monthlyByWeekday": "Mensalmente",
      "rrule.on.weekly": "no",
      "rrule.on.yearly": "no",
      "rrule.weekdays": "(Seg-Sex)",
    }
  },
}

export default resources