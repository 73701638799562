import { Language } from 'rrule/dist/esm/nlp/i18n';

export const RRuleSpanishText: Language = {
  dayNames: ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  tokens: {},
};

const spanishText: any = {
  every: 'cada',
  day: 'dia',
  days: 'dias',
  week: 'semana',
  weeks: 'semanas',
  st: '°',
  nd: '°',
  rd: '°',
  th: '°',
  weekday: 'dia laborable',
  weekdays: 'dias laborables',
  month: 'mes',
  months: 'meses',
  year: 'año',
  on: 'el',
  'on the': 'el',
  and: 'y',
  tomorrow: 'mañana',
  yesterday: 'ayer',
};

export const getSpanishText = function (id: any) {
  return spanishText[id] || id;
};
