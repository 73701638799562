import { DefaultValue, atom, selector } from 'recoil';
import i18n from '../../i18n';
import { initializeState } from '../../utils/state/stateLocalPersistance';

export const SETTINGS_STORAGE_KEY = 'settings';

export enum Language {
  INVALID = 'invalid',
  ENGLISH = 'en',
  ESPAÑOL = 'es',
  FRANÇAIS = 'fr',
  PORTUGUES = 'pt',
}

/*
The following sections need to be updated when adding properties to be persisted locally.
Persisting across devices is not handled with local storage
*/
export interface DeviceSettings {
  darkMode: boolean;
  wifiOnly: boolean;
}

//If this gets updated here, make sure to also update it in the service
export interface UserSettings {
  language?: Language;
  notifications?: NotificationSettings;
}

//If this gets updated here, make sure to also update it in the service
export interface NotificationSettings {
  listCompleted?: boolean;
  assignedToTask?: boolean;
  listOwnershipChanged?: boolean;
}

export interface Settings extends DeviceSettings, UserSettings {}

const defaultSettings: Settings = {
  darkMode: true,
  wifiOnly: false,
  notifications: {
    //If this gets updated here, make sure to also update `defaultNotificationSettings` in the service
    listCompleted: true,
  },
};

// https://recoiljs.org/docs/guides/atom-effects/#backward-compatibility
export const settingsRestorer = (savedSettings: any): Settings => {
  const settings: Settings = { ...defaultSettings };
  if (savedSettings.darkMode !== undefined) {
    settings.darkMode = savedSettings.darkMode;
  }
  if (savedSettings.language !== undefined) {
    settings.language = savedSettings.language;
  }
  if (savedSettings.wifiOnly !== undefined) {
    settings.wifiOnly = savedSettings.wifiOnly;
  }
  if (savedSettings.notifications !== undefined) {
    settings.notifications = savedSettings.notifications;
  }
  return settings;
};

export const initSettingsFromSavedState = (savedSettings: Settings) => {
  if (savedSettings.darkMode) {
    document.body.classList.toggle('dark');
  }
  if (savedSettings.language !== Language.ENGLISH) {
    i18n.changeLanguage(savedSettings.language);
  }
  if (savedSettings.wifiOnly) {
    document.body.classList.toggle('wifiOnly');
  }
};

/* END of sections that need to be updated when adding properties that need to be persisted. */

const initializedSettings: Promise<Settings> = initializeState<Settings>(
  SETTINGS_STORAGE_KEY,
  settingsRestorer,
  initSettingsFromSavedState,
  defaultSettings
);

export const settingsState = atom<Settings>({
  key: 'settingsState',
  default: initializedSettings,
});

const getUserSettingsProperties = (): (keyof UserSettings)[] => {
  // This dummy Record object ensures that it gets updated as properties on the UserSettings type are updated (https://stackoverflow.com/a/53216805/5423329)
  const dummyUserSettings: Record<keyof UserSettings, null> = {
    language: null,
    notifications: null,
  };
  return Object.keys(dummyUserSettings) as (keyof UserSettings)[];
};

const isUserSettingsProperty = (key: keyof Settings): boolean => {
  const userSettingsKeys: string[] = getUserSettingsProperties();
  return userSettingsKeys.includes(key);
};

export const userSettingsState = selector<UserSettings>({
  key: 'userSettingsState',
  get: ({ get }) => {
    const settings: Settings = get(settingsState);
    const userSettingsEntries = Object.entries(settings).filter(([key]) => isUserSettingsProperty(key as any));
    const userSettings: UserSettings = Object.fromEntries(userSettingsEntries);
    return userSettings;
  },
  set: ({ get, set }, newUserSettings) => {
    if (newUserSettings instanceof DefaultValue) {
      return;
    }
    const oldSettings: Settings = get(settingsState);
    const newSettings: Settings = { ...oldSettings, ...newUserSettings };
    set(settingsState, newSettings);
  },
});
