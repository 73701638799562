import { IonCol, IonGrid, IonRow, IonIcon, IonButton, IonModal, IonItem, IonText } from '@ionic/react';
import { calendarNumberOutline, cameraOutline, refreshOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormField from '../../general/FormField';
import { MaxFieldLengths } from '../../../constants/constants';
import { ListDO, TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { useRecoilValue } from 'recoil';
import CalendarView from '../../calendar/components/CalendarView';
import { dueDateText, dueDateTextStyle } from '../../calendar/utils/calendarUtils';
import { recurrenceRuleToNaturalLanguage } from '../../calendar/utils/naturalLanguage';
import PhotosTile from '../photos/PhotosTile';
import { writeAccessRestrictedState } from '../../../App';
import Field, { DisablePointerState } from './Field';
import { Language, settingsState } from '../../settings/settingsState';
import useCamera from '../photos/state/useCamera';
import useIsPhotoRequired from '../photos/state/useIsPhotoRequired';

interface TaskDetailsViewProps {
  task: TaskDO;
  isListOwner: boolean;
  disbablePointerEvets: DisablePointerState;
  setPointerEvents: React.Dispatch<React.SetStateAction<DisablePointerState>>;
  list: ListDO;
}

const TaskDetailsView: React.FC<TaskDetailsViewProps> = ({
  task,
  isListOwner,
  disbablePointerEvets,
  setPointerEvents,
  list,
}) => {
  const [showCompleteButton, setShowCompleteButton] = useState<boolean>(true);

  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const { language } = useRecoilValue(settingsState);
  const { t } = useTranslation();
  const history = useHistory();
  const { takePhoto } = useCamera(task.taskId);

  const { isPhotoRequiredToComplete } = useIsPhotoRequired(task, list.qmInspectionId ? true : false);

  const modalRef = useRef<HTMLIonModalElement>(null);

  const completeTask = async () => {
    if (!task) return;
    await _task.completeTaskByID(task.taskId);
    history.goBack();
  };

  const onAddPhotoClickHandler = async () => {
    await takePhoto();
  };

  const onCalendarCancelClickHandler = () => {
    modalRef.current?.dismiss();
  };

  const completeButton: JSX.Element = (
    <div
      slot="fixed"
      style={{
        position: 'absolute',
        transform: 'translate(-50%)',
        bottom: '0.75rem',
        left: '50%',
      }}
    >
      <IonButton className="custom-button-radius" style={{ width: '14rem' }} color="primary" onClick={completeTask}>
        {t('completeTask')}
      </IonButton>
    </div>
  );

  const takePhotoButton: JSX.Element = (
    <div
      slot="fixed"
      style={{
        position: 'absolute',
        transform: 'translate(-50%)',
        bottom: '0.75rem',
        left: '50%',
      }}
    >
      <IonButton
        className="custom-button-radius"
        style={{ width: '18rem' }}
        color="primary"
        onClick={onAddPhotoClickHandler}
      >
        <div className="d-flex ion-align-items-center ion-justify-content-center ion-padding-horizontal">
          <IonIcon icon={cameraOutline} style={{ marginRight: '.5rem' }} slot="start" size="large" />
          <IonText style={{ paddingTop: '.25rem' }}>{t('requirePhoto.takePhoto')}</IonText>
        </div>
      </IonButton>
    </div>
  );

  if (!task) return null;

  return (
    <>
      <IonGrid fixed={true}>
        <IonRow class="ion-justify-content-center ion-align-items-center">
          <IonCol size="12">
            <div
              className={
                disbablePointerEvets.disableEvents && disbablePointerEvets.inputFieldOpened !== 'taskName'
                  ? 'disable-pointer-events'
                  : ''
              }
            >
              <FormField lessMarginBottom>
                <Field
                  task={task}
                  taskPropKey="taskName"
                  inputMaxLength={MaxFieldLengths.TASK_NAME}
                  fontSize="32"
                  labelFontWeight="font-weight-600"
                  setShowCompleteButton={setShowCompleteButton}
                  setDisablePointerEvents={setPointerEvents}
                  doesEnterKeyBlur={true}
                  editable={isListOwner}
                />
              </FormField>
            </div>
            {/* Due Date Description */}
            {(isListOwner || task.dueDate) && (
              <div
                id="open-modal"
                className={`d-flex ion-justify-content-center ${
                  disbablePointerEvets.disableEvents ? 'disable-pointer-events' : ''
                }`}
              >
                {/* Due Dates */}
                <div>
                  <IonItem lines="none" className="ion-align-self-start">
                    <IonIcon icon={calendarNumberOutline} color="primary" style={{ marginRight: '.5rem' }} />
                    <IonText color={dueDateTextStyle(task)}>
                      {task.dueDate ? dueDateText(task.dueDate, task.timezone) : t('dueDate')}
                    </IonText>
                  </IonItem>
                </div>
                {/* Repeat */}
                <div>
                  <IonItem lines="none">
                    <IonIcon icon={refreshOutline} color="primary" style={{ marginRight: '.5rem' }} />
                    {/* TODO: Handle long natural language text... */}
                    <IonText color={task.recurrence ? 'heavy' : 'light'}>
                      {task.recurrence
                        ? recurrenceRuleToNaturalLanguage(task.recurrence, language ?? Language.ENGLISH)
                        : t('repeat')}
                    </IonText>
                  </IonItem>
                </div>
              </div>
            )}
            <div
              className={
                disbablePointerEvets.disableEvents && disbablePointerEvets.inputFieldOpened !== 'instructions'
                  ? 'disable-pointer-events'
                  : ''
              }
            >
              <FormField moreMarginBottom>
                <Field
                  task={task}
                  taskPropKey="instructions"
                  inputMaxLength={MaxFieldLengths.TASK_INSTRUCTIONS}
                  setShowCompleteButton={setShowCompleteButton}
                  setDisablePointerEvents={setPointerEvents}
                  editable={isListOwner}
                />
              </FormField>
            </div>

            <div className={disbablePointerEvets.disableEvents ? 'disable-pointer-events' : ''}>
              <PhotosTile task={task} isListOwner={isListOwner} />
            </div>

            <div
              className={
                disbablePointerEvets.disableEvents && disbablePointerEvets.inputFieldOpened !== 'notes'
                  ? 'disable-pointer-events'
                  : ''
              }
              style={{ marginBottom: '5rem' }}
            >
              <FormField>
                <Field
                  task={task}
                  taskPropKey="notes"
                  lineClamp={5}
                  inputMaxLength={MaxFieldLengths.TASK_NOTES}
                  setShowCompleteButton={setShowCompleteButton}
                  setDisablePointerEvents={setPointerEvents}
                />
              </FormField>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isListOwner && (
        <IonModal initialBreakpoint={1} breakpoints={[0, 1]} handle={false} ref={modalRef} trigger="open-modal">
          <CalendarView onCancel={onCalendarCancelClickHandler} tasks={[task]} />
        </IonModal>
      )}
      {!isWriteAccessRestricted && !task.isCompleted && showCompleteButton
        ? isPhotoRequiredToComplete
          ? takePhotoButton
          : completeButton
        : null}
    </>
  );
};

export default TaskDetailsView;
