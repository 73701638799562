import { format } from 'date-fns';
import { es, enUS, pt, fr } from 'date-fns/locale';
import { RRule } from 'rrule';
import i18n from '../../../i18n';
import { Language } from '../../settings/settingsState';
import { getSpanishText, RRuleSpanishText } from './rruleSpanishNaturalLanguage';
import { applyFrenchLanguageSpecificTranslation, getFrenchText, RRuleFrenchText } from './rruleFrenchNaturalLanguage';
import {
  applyPortugueseLanguageSpecificTranslation,
  getPortugueseText,
  RRulePortugueseText,
} from './rrulePortugueseNaturalLanguage';

// POC trying to generate natural language text for the RRule object using i18n
// const testRecurrenceRuleToNaturalLanguage = (rule: RRule) => {
//   const every = i18n.t(`rrule.every.${Frequency[rule.options.freq]}`, {count: rule.options.interval});
//   const frequency = i18n.t(`rrule.freq.${Frequency[rule.options.freq]}`, {count: rule.options.interval});
//   const intervalKey = rule.options.interval === 1 ? `rrule.interval.1` : `rrule.interval.other`;

//   return i18n.t('naturalLanguageRRule', {
//     every: every,
//     interval: i18n.t(intervalKey, { interval: rule.options.interval }),
//     freq: frequency,
//   });
// };

export function recurrenceRuleToNaturalLanguage(recurrenceRule: string | RRule | null | undefined, language: Language) {
  // If the recurrenceRule is null or undefined, we exit the function
  if (!recurrenceRule) return;

  // We check if the value of recurrenceRule is a string or an object (RRule)
  // If it's a string, we parse it to an RRule object
  const ruleObject = typeof recurrenceRule === 'string' ? RRule.fromString(recurrenceRule) : recurrenceRule;

  // We generate the natural language text for the ruleObject
  const naturalLanguageText = generateNaturalLanguageText(ruleObject, language);

  return naturalLanguageText;
}

// This function converts a monthly by weekday rule to natural language
// This is a custom rule that does not have natural language support by the rrule library
// We have to create the natural language text ourselves and handle it separately from the recurrenceRuleToNaturalLanguage function
// This also takes care of ordinal suffixes for the day of the month
export function monthlyByWeekdayToNaturalLanguage(date: Date | null, language: Language) {
  if (!date) return;
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  const formatOrdinals = (n: number) => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);

    // Handle the case for 1st in French
    if (language === 'fr' && n === 1) return `${n}ᵉʳ`;
    if (language === 'fr' && n !== 1) return `${n}${'ᵉ'}`;

    //For Spanish and Portuguese we use the ° symbol for the ordinal suffix
    if (language === 'es') return `${n}${'°'}`;
    if (language === 'pt') return `${n}${'º'}`;

    // For English we use the suffixes
    return `${n}${suffix}`;
  };

  const nth = formatOrdinals(Math.ceil(date.getDate() / 7));
  const dayOfTheWeek = format(date, ' EEEE', {
    locale: getLanguageLocale(language),
  });

  if (language === 'pt') {
    return `${i18n.t('repeatMenu.monthlyByWeekday')} ${
      dayOfTheWeek.includes('sábado') || dayOfTheWeek.includes('domingo') ? 'no' : 'na'
    } ${nth} ${dayOfTheWeek}`;
  }
  return `${i18n.t('repeatMenu.monthlyByWeekday')} ${nth} ${format(date, ' EEEE', {
    locale: getLanguageLocale(language),
  })}`;
}

// Generate natural language text for the RRule object
// We use the toText method from the rrule library to generate the natural language text
// We pass the language-specific text to the toText method to generate the text in the desired language
const generateNaturalLanguageText = (rule: RRule, language: Language) => {
  let generatedText = '';

  if (language === 'en' || language === 'invalid') {
    generatedText = rule.toText();
  } else if (language === 'es') {
    generatedText = rule.toText(getSpanishText, RRuleSpanishText);
  } else if (language === 'fr') {
    generatedText = rule.toText(getFrenchText, RRuleFrenchText);
    generatedText = applyFrenchLanguageSpecificTranslation(generatedText);
  } else if (language === 'pt') {
    generatedText = rule.toText(getPortugueseText, RRulePortugueseText);
    // since pt has special handling for masculine and feminine ordinal suffixes, we need to handle it separately ej '1st' -> '1º' vs '1ª'
    // and also masculine and feminine prepositions for the day of the week ej. 'on Saturday' -> 'no sábado' vs 'na segunda-feira'
    generatedText = applyPortugueseLanguageSpecificTranslation(generatedText);
  } else {
    generatedText = rule.toText();
  }

  // We capitalize the first letter of the generated text
  const capitalizedText = generatedText.charAt(0).toUpperCase() + generatedText.slice(1);

  // We check if the rule has an interval property to determine if it's a custom rule and append the custom text
  if (rule.origOptions.interval) {
    return `${i18n.t('repeatMenu.custom')} (${capitalizedText.replace('(~ approximate)', '')})`;
  }

  //if the rule is not custom, we return the capitalized text  and the natural language details
  return `${capitalizedText.replace('(~ approximate)', '')} ${naturalLanguageDetails(rule, language)}`;
};

// Utility function to get the locale for the date-fns library
// We pass the language to the function and return the locale object for the desired language
const getLanguageLocale = (language: Language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'pt':
      return pt;
    default:
      return undefined;
  }
};

// Utility function to generate natural language details for the RRule object
// We pass the rule and the language to the function
// We check the frequency of the rule and generate the natural language details accordingly
// This is because the rrule library does not provide natural language details for the rules
// We have to generate the details ourselves
const naturalLanguageDetails = (rule: RRule, language: Language) => {
  const dtstartValue = rule.options.dtstart;
  switch (rule.options.freq) {
    case RRule.YEARLY:
      if (language === 'fr')
        return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, 'd MMMM', {
          locale: getLanguageLocale(language),
        })}`;

      if (language === 'pt') {
        return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, 'd', {
          locale: getLanguageLocale(language),
        })} de ${format(dtstartValue, 'MMMM', {
          locale: getLanguageLocale(language),
        })}`;
      }

      return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, ' MMM. do', {
        locale: getLanguageLocale(language),
      })}`;

    case RRule.MONTHLY:
      if (rule.origOptions.byweekday) return '';
      return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, language === 'en' ? 'do' : 'd', {
        locale: getLanguageLocale(language),
      })}`;

    case RRule.WEEKLY:
      if (language === 'pt') {
        if (rule.options.byweekday.length > 1) return ` ${i18n.t('rrule.weekdays')}`;
        const dayOfTheWeek = `${format(dtstartValue, ' EEEE', {
          locale: getLanguageLocale(language),
        })}`;

        if (dayOfTheWeek.includes('sábado') || dayOfTheWeek.includes('domingo')) {
          return `no ${dayOfTheWeek}`;
        }

        return `na ${dayOfTheWeek}`;
      }
      if (rule.options.byweekday.length > 1) return ` ${i18n.t('rrule.weekdays')}`;
      return `${i18n.t('rrule.on.weekly')} ${format(dtstartValue, ' EEEE', {
        locale: getLanguageLocale(language),
      })}`;

    case RRule.DAILY:
      return '';
  }
};
