import { Language } from 'rrule/dist/esm/nlp/i18n';

export const applyFrenchLanguageSpecificTranslation = (text: string) => {
  const pluralOcurrency = ['jours', 'semaines', 'mois', 'ans'];

  if (pluralOcurrency.some((word) => text.includes(word))) {
    if (text.includes('ans')) {
      text.replace('ans', 'ans, en');
    }
    return text.replace('chaque', 'tous les');
  }

  return text;
};

export const RRuleFrenchText: Language = {
  dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  monthNames: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  tokens: {},
};

const frenchText: any = {
  every: 'chaque',
  day: 'jour',
  days: 'jours',
  week: 'semaine',
  weeks: 'semaines',
  st: 'ᵉʳ',
  nd: 'ᵉ',
  rd: 'ᵉ',
  th: 'ᵉ',
  weekday: 'jour ouvré',
  weekdays: 'jour ouvré',
  month: 'mois',
  months: 'mois',
  year: 'année',
  years: 'ans',
  on: 'le',
  'on the': 'le',
  and: 'et',
  tomorrow: 'demain',
  yesterday: 'hier',
};

export const getFrenchText = function (id: any) {
  return frenchText[id] || id;
};
