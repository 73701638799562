import { Language } from 'rrule/dist/esm/nlp/i18n';

export const applyPortugueseLanguageSpecificTranslation = (text: string) => {
  const masculineWords = ['sábado', 'domingo'];
  if (text.match(/^Todos os meses no (\d+)º(?: ([\w-]+))?$/)) {
    if (!text.includes('sábado') && !text.includes('domingo')) {
      return text.replace('no', 'na').replace('º', 'ª');
    }
  }

  if (text.includes('Todos os semana')) {
    const match = text.match(/no ([a-zA-Zá-ú\-]+)/);
    if (match && !(match[1] === 'sábado' || match[1] === 'domingo')) {
      return text.replace('no', 'na').replace('Todos os semana', 'Todas as semanas');
    }
    return text.replace('Todos os semana', 'Todas as semanas');
  }

  if (/Todos os (\d+) semanas/.test(text)) {
    const match = text.match(/no ([a-zA-Zá-ú\-]+)/);
    if (match && !(match[1] === 'sábado' || match[1] === 'domingo')) {
      return text.replace('no', 'na').replace('Todos os', 'Todas as');
    }
    return text.replace('Todos os', 'Todas as');
  }

  if (text.includes('Todos os dia')) {
    return text.replace('dia', 'dias');
  }

  if (masculineWords.some((word) => text.includes(word))) {
    return text;
  }

  return text;
};

export const RRulePortugueseText: Language = {
  dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
  monthNames: [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
  tokens: {},
};

const portugueseText: any = {
  every: 'Todos os',
  day: 'dia',
  days: 'dias',
  week: 'semana',
  weeks: 'semanas',
  st: 'º',
  nd: 'º',
  rd: 'º',
  th: 'º',
  weekday: 'dia da semana',
  weekdays: 'dias da semana',
  month: 'meses',
  months: 'meses',
  year: 'anos',
  on: 'no',
  'on the': 'no',
  and: 'e',
  tomorrow: 'amanhã',
  yesterday: 'ontem',
};

export const getPortugueseText = function (id: any) {
  return portugueseText[id] || id;
};
