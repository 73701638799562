import {
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { RRule } from 'rrule';
import { Language, settingsState } from '../../settings/settingsState';
import { slideFromRightEnterAnimation, slideFromRightLeaveAnimation } from '../utils/calendarAnimation';
import { monthlyByWeekdayToNaturalLanguage } from '../utils/naturalLanguage';
import CustomRecurrenceSelect from './CustomRecurrenceSelect';

interface RecurrenceOption {
  label: string;
  value: string;
  rrule: RRule | null;
  detail?: boolean;
}

const options: RecurrenceOption[] = [
  {
    label: 'repeatMenu.none',
    value: 'none',
    rrule: null,
  },
  {
    label: 'repeatMenu.daily',
    value: 'daily',
    rrule: new RRule({
      freq: RRule.DAILY,
    }),
  },
  {
    label: 'repeatMenu.weekdays',
    value: 'weekdays',
    rrule: new RRule({
      freq: RRule.WEEKLY,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      wkst: RRule.SU,
    }),
  },
  {
    label: 'repeatMenu.weekly',
    value: 'weekly',
    rrule: new RRule({
      freq: RRule.WEEKLY,
    }),
  },
  {
    label: 'repeatMenu.monthly',
    value: 'monthly',
    rrule: new RRule({
      freq: RRule.MONTHLY,
    }),
  },
  {
    label: 'Monthly by Weekday',
    value: 'monthly-weekday',
    rrule: new RRule({
      freq: RRule.MONTHLY,
    }),
  },
  {
    label: 'repeatMenu.yearly',
    value: 'yearly',
    rrule: new RRule({
      freq: RRule.YEARLY,
    }),
  },
  {
    label: 'repeatMenu.custom',
    value: 'custom',
    rrule: null,
    detail: true,
  },
];

export const getRecurrenceRuleByOption = (optionValue: string): RRule | null => {
  const recurrenceOption: RecurrenceOption | undefined = options.find((o) => o.value === optionValue);
  if (!recurrenceOption) {
    throw new Error('Failed to find recurrence option for recurrence option value');
  }
  return recurrenceOption.rrule;
};

interface RecurrenceSelectProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect: (value: string | RRule) => void;
  recurrenceOption: string;
  calendarDate: Date | null;
  recurrenceValue: RRule | null;
}

const RecurrenceSelect: React.FC<RecurrenceSelectProps> = ({
  isOpen,
  setIsOpen,
  onSelect,
  recurrenceOption,
  calendarDate,
  recurrenceValue,
}) => {
  const [selection, setSelection] = useState<string>(recurrenceOption);
  const [isCustomSelect, setIsCustomSelect] = useState(false);

  const { language } = useRecoilValue(settingsState);
  const { t } = useTranslation();

  const onRadioChangeHandler = (value: string) => {
    if (value !== 'custom') return setSelection(value);
    setSelection(value);
  };

  const cancelClickHandler = () => {
    setSelection(recurrenceOption);
    setIsOpen(false);
  };

  const doneClickHandler = () => {
    onSelect(selection);
    setIsOpen(false);
  };

  const customSelectHandler = (rule: RRule) => {
    onSelect(rule);
    setIsCustomSelect(false);
    setIsOpen(false);
  };

  return (
    <>
      <IonModal
        isOpen={isOpen}
        enterAnimation={slideFromRightEnterAnimation}
        leaveAnimation={slideFromRightLeaveAnimation}
      >
        <IonContent class="ion-padding-horizontal" style={{ '--background': 'var(--toolbar-background)' }}>
          <IonGrid>
            <IonList style={{ background: 'none' }}>
              <IonRadioGroup value={selection} onIonChange={(event) => onRadioChangeHandler(event.detail.value)}>
                {options.map(({ label, value, detail }) => (
                  <IonItem
                    key={value}
                    detail={detail}
                    color="custom-overlay"
                    lines="none"
                    onClick={() => value === 'custom' && setIsCustomSelect(true)}
                  >
                    <IonRadio value={value} class="ion-margin-end" />
                    <IonLabel>
                      {value === 'monthly-weekday'
                        ? `${monthlyByWeekdayToNaturalLanguage(
                            calendarDate ?? new Date(),
                            language ?? Language.ENGLISH
                          )}`
                        : t(label)}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          </IonGrid>
        </IonContent>
        <IonFooter class="ion-no-border">
          <IonToolbar>
            <IonButton slot="start" fill="clear" onClick={cancelClickHandler}>
              <IonText color="primary">{t('cancel')}</IonText>
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={doneClickHandler}>
              <IonText color="primary">{t('done')}</IonText>
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <CustomRecurrenceSelect
        isOpen={isCustomSelect}
        setIsOpen={setIsCustomSelect}
        onCustomSelect={customSelectHandler}
        calendarDate={calendarDate}
        recurrenceValue={recurrenceValue}
      />
    </>
  );
};

export default RecurrenceSelect;
